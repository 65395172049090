<!-- Modal to link users to other portals -->
<template>
  <v-menu
    bottom
    right
    offset-y
    :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        color="#f7f7f7">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>
    <v-card max-width="300" tile>
      <v-list nav dense>
        <v-subheader>
          Portals
          <v-icon small class="ml-2">mdi-launch</v-icon>
        </v-subheader>
        <div class="portal-links-container">
          <v-list-item
            v-for="portal in portals"
            :key="portal.key"
            class="portal-link"
            link
            @click="navigate(portal)"
            style="min-height: auto">
            <div class="portal-link-content">
              <v-btn
                plain
                icon
                class="mx-auto">
                <v-icon>{{ portal.icon }}</v-icon>
              </v-btn>
              <v-list-item-action-text class="pa-0 ma-0">
                {{ portal.name }}
              </v-list-item-action-text>
            </div>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'PortalNavMenu',
  props: {
    active: String
  },
  computed: {
    portals () {
      return [
        { name: 'Ad Planner', key: 'adplan', icon: 'mdi-newspaper-variant-outline' },
        { name: 'Billing', key: 'billing', icon: 'mdi-calendar-clock' },
        { name: 'Customer', key: 'customer', icon: 'mdi-storefront' },
        { name: 'Items', key: 'item', icon: 'mdi-barcode-scan' },
        { name: this.$auth.tenant === 'jbg' ? 'Documents' : 'Partner', key: 'partner', icon: 'mdi-folder-account-outline' },
        { name: 'Promotions', key: 'promo', icon: 'mdi-tag-outline' }
      ]
    }
  },
  methods: {
    navigate (portal) {
      if (portal.key === this.active) return
      const url = this.buildPortalUrl(portal.key)
      window.location.href = url
    },
    buildPortalUrl (key) {
      let baseUrl = this.getPortalBaseUrl(key)
      let prefix = this.$auth.tenantURLPrefix
      if (prefix) {
        return `https://${prefix}.${baseUrl}`
      }
      return `https://${baseUrl}`
    },
    getPortalBaseUrl (key) {
      switch (key) {
        case 'adplan':
          return process.env.VUE_APP_ADPLAN_PORTAL
        case 'billing':
          return process.env.VUE_APP_BILLING_PORTAL
        case 'customer':
          return process.env.VUE_APP_CUSTOMER_PORTAL
        case 'item':
          return process.env.VUE_APP_ITEM_PORTAL
        case 'partner':
          return process.env.VUE_APP_PARTNER_PORTAL
        case 'promo':
          return process.env.VUE_APP_PROMO_PORTAL
        default:
          return ''
      }
    }
  }
}
</script>
<style>
.portal-links-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3px;
}

.portal-link {
  display: grid;
  justify-content: center;
  padding: 4px;
  height: 100%;
}

.portal-link-content {
  display: grid;
  justify-content: center;
  max-height: 53px;
  text-align: center;
}
</style>